<template>
  <main>
    <section class='GallaryItems max_w'>
      <gallary-items></gallary-items>
    </section>
  </main>
</template>
<style lang="scss" scoped>
</style>
<script>
import GallaryItems from '../../components/GallaryItems.vue';
export default {
  name: 'Works',
  components: {
    GallaryItems
  },


};
</script>
