<template>
  <v-footer padless class="mt-8">
    <v-col class="text-center text-caption blue lighten-2 white--text" cols="12 ">&copy; 2021 - TANAKA AI.</v-col>
  </v-footer>  
</template>

<script>
export default {
  name:'Footer',
  data(){
    return{
    }
  },
}
</script>