<template>
  <main>
    <section class="skills max_w">
      <Skills></Skills>
    </section>
  </main>
</template>
<script>
import Skills from '../../components/Skills'
export default {
  
  name: 'Skill',
  components: {
    Skills
  },


};
</script>
