<template>
  <main>
    <section class='workItems max_w'>
      <work-items></work-items>
    </section>
  </main>
</template>
<style lang="scss" scoped>
</style>
<script>
import WorkItems from '../../components/WorkItems.vue';
export default {
  name: 'Works',
  components: {
    WorkItems
  },


};
</script>
