import 'font-awesome/css/font-awesome.min.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa4'
  },
  theme: {
    dark:false,
    light:true,
    themes: {
      light: {
        background:'#ECEFF1'
      }
    }
  }
});
